<template>
  <div class="table-responsive">
    <table class="table table-small" v-if="caseObj">
      <thead>
      <tr>
        <th>Service No.</th>
        <th>ชื่อบริการ</th>
        <th>จำนวน</th>
        <th>หน่วยนับ</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(service, i) in caseObj.services"
          :key="'service_'+service.id">
        <td>{{ i+1 }}</td>
        <td>{{ service.name_th }}</td>
        <td>1.00</td>
        <td>{{ service.serviceUnit.name_th }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: ['caseObj']
}
</script>