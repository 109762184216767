<template>
  <div class="row mt-3">
    <div class="col-md-4">
      <div class="box">
        <div class="box-header with-border small-box-header">
          <h4 class="box-title">การตรวจร่างกาย</h4>
        </div>
        <div class="box-body">
          <div class="form-group margin-less-form-group row">
            <label class="col-sm-3 col-form-label">PE :</label>
            <div class="col-sm-9">
              <input class="form-control form-control-sm" type="text">
            </div>
          </div>
          <div class="form-group margin-less-form-group row">
            <label class="col-sm-3 col-form-label">GA :</label>
            <div class="col-sm-9">
              <input class="form-control form-control-sm" type="text">
            </div>
          </div>
          <div class="form-group margin-less-form-group row">
            <label class="col-sm-3 col-form-label">T :</label>
            <div class="col-sm-9">
              <input class="form-control form-control-sm" type="text">
            </div>
          </div>
          <div class="form-group margin-less-form-group row">
            <label class="col-sm-3 col-form-label">P :</label>
            <div class="col-sm-9">
              <input class="form-control form-control-sm" type="text">
            </div>
          </div>
          <div class="form-group margin-less-form-group row">
            <label class="col-sm-3 col-form-label">RR :</label>
            <div class="col-sm-9">
              <input class="form-control form-control-sm" type="text">
            </div>
          </div>
          <div class="form-group margin-less-form-group row">
            <label class="col-sm-3 col-form-label">BP :</label>
            <div class="col-sm-9">
              <input class="form-control form-control-sm" type="text">
            </div>
          </div>
          <div class="form-group margin-less-form-group row">
            <label class="col-sm-3 col-form-label">น้ำหนัก :</label>
            <div class="col-sm-9">
              <input class="form-control form-control-sm" type="text">
            </div>
          </div>
          <div class="form-group margin-less-form-group row">
            <label class="col-sm-3 col-form-label">ส่วนสูง :</label>
            <div class="col-sm-9">
              <input class="form-control form-control-sm" type="text">
            </div>
          </div>
          <div class="form-group margin-less-form-group row">
            <label class="col-sm-3 col-form-label">BMI :</label>
            <div class="col-sm-9">
              <input class="form-control form-control-sm" type="text">
            </div>
          </div>
          <div class="form-group margin-less-form-group row">
            <label class="col-sm-3 col-form-label">FPS :</label>
            <div class="col-sm-9">
              <input class="form-control form-control-sm" type="text">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-8">
      <div class="box">
        <div class="box-header with-border small-box-header">
          <h4 class="box-title">อาการสำคัญ/ประวัติการเจ็บปวดปัจจุบัน/ประวัติการเจ็บปวดในอดีต</h4>
        </div>
        <div class="box-body">
          <div class="form-group margin-less-form-group row">
            <label class="col-sm-1 col-form-label">CC :</label>
            <div class="col-sm-7">
              <input class="form-control form-control-sm" type="text">
            </div>
          </div>
          <div class="form-group margin-less-form-group row">
            <label class="col-sm-1 col-form-label">HPI :</label>
            <div class="col-sm-7">
              <input class="form-control form-control-sm" type="text">
            </div>
          </div>
          <div class="form-group margin-less-form-group row">
            <label class="col-sm-1 col-form-label">PMH :</label>
            <div class="col-sm-7">
              <input class="form-control form-control-sm" type="text">
            </div>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="box-header with-border small-box-header">
          <h4 class="box-title">รายละเอียดการตรวจ</h4>
        </div>
        <div class="box-body">
          <p>มาใช้บริการครั้งแรก xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</p>
        </div>
      </div>
    </div>
  </div>
</template>