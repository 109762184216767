<template>
  <div class="content-header">
    <div class="d-flex align-items-center">
      <div class="me-auto">
        <h3 class="page-title">{{ $t('patients.patients') }}</h3>
        <div class="d-inline-block align-items-center">
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#"><i class="mdi mdi-home-outline"></i></a></li>
              <li class="breadcrumb-item active" aria-current="page">{{ $t('patients.patients') }}</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="box">
        <div class="box-header small-box-header">
          <div class="row">
            <div class="col-md-7">
              <div class="input-group">
                <input type="text"
                       @keyup="searchText($event.target.value)"
                       placeholder="ค้นหาด้วยการระบุ HN, ชื่อ นามสกุล, ชื่อเล่น, เบอร์โทรศัพท์"
                       class="form-control form-control-sm">
                <span class="input-group-text"><i class="fa fa-search"></i></span>
              </div>
            </div>
            <div class="col-md-2">
              <select class="form-select form-select-sm"
                      @change="refreshData"
                      v-model="queryParams.gender">
                <option value="">{{ $t('patients.gender') }}</option>
                <option value="Male">{{ $t('patients.male') }}</option>
                <option value="Female">{{ $t('patients.female') }}</option>
                <option value="Other">{{ $t('patients.other') }}</option>
              </select>
            </div>
            <div class="col-md-2">
              <select class="form-select form-select-sm"
                      @change="refreshData"
                      v-model="queryParams.status">
                <option value="">{{ $t('patients.all_status') }}</option>
                <option value="1">{{ $t('patients.active') }}</option>
                <option value="0">{{ $t('patients.inactive') }}</option>
              </select>
            </div>
            <div class="col-md-1">
              <div class="d-grid">
                <button @click="$refs.formModal.show()"
                        class="btn btn-primary btn-sm">+ {{ $t('patients.add_new') }}</button>
              </div>
            </div>
          </div>
        </div>
        <div class="box-body">
          <table-component :columns="columns"
                           :rows="patients"
                           :sortable="sortable"
                           @sorting="sorting"
                           :pagination="pagination"
                           :search="false"
                           @pageChanged="pageChanged">

            <template v-slot:action="{ row }">
              <router-link :to="{name: 'patients_details', params: {id: row.id}}"
                           class="text-muted"><i class="fas fa-eye"></i> {{ $t('setting.view') }}
              </router-link> |

              <a href="#" class="text-muted"
                 @click.prevent="$refs.formModal.show(row)"><i class="fas fa-pen"></i> {{ $t('setting.edit') }}
              </a> |

              <a href="#" class="text-muted"
                 @click.prevent="deleteData(row)"><i class="fas fa-trash"></i> {{ $t('setting.delete') }}</a>
            </template>
          </table-component>
        </div>
      </div>
    </div>
  </div>

  <form-modal ref="formModal" @added="loadData"></form-modal>
</template>

<script>
import TableComponent from "@/components/TableComponent";
import FormModal from "./FormModal";
export default {
  components: {TableComponent, FormModal},
  data() {
    return {
      patients: [],
      pagination: null,
      queryParams: {
        status: '',
        gender: '',
        page: 1,
        search: '',
        sort: 'hn',
        sort_order: 'asc',
        per_page: 10
      },
      sortable: {
        order: 'hn',
        sort: 'asc'
      },
      columns: [
        {
          label: this.$t('patients.hn'),
          field: "hn",
          sortable: true,
          searchable: true,
        },
        {
          label: this.$t('patients.name'),
          field: 'full_name',
          sortable: true,
          searchable: true,
        },
        {
          label: this.$t('patients.nickname'),
          field: 'nick_name',
          sortable: true,
          searchable: true,
        },
        {
          label: this.$t('patients.age'),
          field: 'age',
          sortable: false,
          searchable: false,
        },
        {
          label: this.$t('patients.gen'),
          field: 'gender',
          sortable: true,
          searchable: false,
        },
        {
          label: this.$t('patients.phone'),
          field: 'mobile',
          sortable: false,
          searchable: true,
        },
        {
          label: this.$t('patients.status'),
          field: 'status',
          sortable: true,
          searchable: false,
          display(row) {
            if (row.status === 1)
              return '<span class="badge badge-success-light">Active</span>';
            else
              return '<span class="badge badge-danger-light">Inactive</span>';
          }
        },
        {
          label: this.$t('patients.action'),
          field: 'action',
          sortable: false,
          slot: true,
          class: ['text-end']
        }
      ],
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.patients = [];

      this.axios.get('/patients', {params: this.queryParams})
        .then((response) => {
          this.patients = response.data.data;
          this.pagination = response.data.meta;
        })
    },
    deleteData(row) {
      this.$swal({
        title: this.$t('patients.are_you_sure_want_to_delete'),
        text: this.$t('patients.you_wont_be_able_to_revert_this'),
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$t('patients.cancel'),
        confirmButtonText: this.$t('patients.yes_delete_it'),
        confirmButtonColor: '#dd4b39',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete('/patients/'+row.id).then(() => {
            this.loadData();
            this.$swal.fire(
              this.$t('patients.deleted'),
              this.$t('patients.patient_has_been_deleted'),
              'success'
            )
          });
        }
      });
    },
  }
}
</script>