<template>
  <template v-if="form && form.emergencyContacts && form.emergencyContacts.length">
    <div class="box" v-for="(emergencyContact, i) in form.emergencyContacts"
         :key="'contact_'+i">
      <div class="box-header small-box-header with-border">
        <div class="row">
          <div class="col-6">
            <h4 class="box-title">ผู้ติดต่อฉุกเฉิน</h4>
          </div>
          <div class="col-6 text-end">
            <a href="" @click.prevent="removeEmergencyContact(i)">
              <i class="fas fa-trash"></i>
            </a>
          </div>
        </div>
      </div>
      <div class="box-body">
        <div class="row">
          <div class="col-6">
            <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('emergencyContact.name_prefix_id')}]">
              <label class="col-sm-4 col-form-label">คำนำหน้า</label>
              <div class="col-sm-8">
                <select class="form-select form-select-sm"
                        v-model="emergencyContact.name_prefix_id">
                  <option value="">Choose</option>
                  <option :value="prefix.id"
                          v-for="prefix in prefixes"
                          :key="'prefix_'+prefix.id">{{ prefix.prefix }}</option>
                </select>

                <v-errors :errors="errorFor('emergencyContact.name_prefix_id')"></v-errors>
              </div>
            </div>
            <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('emergencyContact.name')}]">
              <label class="col-sm-4 col-form-label">ชื่อเต็ม</label>
              <div class="col-sm-8">
                <input class="form-control form-control-sm"
                       v-model="emergencyContact.name"
                       type="text">

                <v-errors :errors="errorFor('emergencyContact.name')"></v-errors>
              </div>
            </div>
            <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('emergencyContact.relationship_id')}]">
              <label class="col-sm-4 col-form-label">ความสัมพันธ์</label>
              <div class="col-sm-8">
                <select class="form-select form-select-sm"
                        v-model="emergencyContact.relationship_id">
                  <option value="">Choose</option>
                  <option :value="relationship.id"
                          v-for="relationship in relationships"
                          :key="'relationship_'+relationship.id">{{ relationship.name }}</option>
                </select>

                <v-errors :errors="errorFor('emergencyContact.relationship_id')"></v-errors>
              </div>
            </div>
            <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('emergencyContact.relationship_other')}]">
              <label class="col-sm-4 col-form-label">ความสัมพันธ์ (อื่น)</label>
              <div class="col-sm-8">
                <input class="form-control form-control-sm"
                       v-model="emergencyContact.relationship_other"
                       type="text">

                <v-errors :errors="errorFor('emergencyContact.relationship_other')"></v-errors>
              </div>
            </div>
            <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('emergencyContact.mobile_country_code')}]">
              <label class="col-sm-4 col-form-label">รหัสประเทศ (มือถือ)</label>
              <div class="col-sm-8">
                <input class="form-control form-control-sm"
                       v-model="emergencyContact.mobile_country_code"
                       type="text">

                <v-errors :errors="errorFor('emergencyContact.mobile_country_code')"></v-errors>
              </div>
            </div>
            <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('emergencyContact.mobile')}]">
              <label class="col-sm-4 col-form-label">เบอร์มือถือ</label>
              <div class="col-sm-8">
                <input class="form-control form-control-sm"
                       v-model="emergencyContact.mobile"
                       type="text">

                <v-errors :errors="errorFor('emergencyContact.mobile')"></v-errors>
              </div>
            </div>
            <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('emergencyContact.mobile_alternative')}]">
              <label class="col-sm-4 col-form-label">เบอร์มือถือ (สำรอง)</label>
              <div class="col-sm-8">
                <input class="form-control form-control-sm"
                       v-model="emergencyContact.mobile_alternative"
                       type="text">

                <v-errors :errors="errorFor('emergencyContact.mobile_alternative')"></v-errors>
              </div>
            </div>
            <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('emergencyContact.email')}]">
              <label class="col-sm-4 col-form-label">อีเมล</label>
              <div class="col-sm-8">
                <input class="form-control form-control-sm"
                       v-model="emergencyContact.email"
                       type="text">

                <v-errors :errors="errorFor('emergencyContact.email')"></v-errors>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('emergencyContact.country_id')}]">
              <label class="col-sm-4 col-form-label">ประเทศ</label>
              <div class="col-sm-8">
                <select class="form-select form-select-sm"
                        v-model="emergencyContact.country_id">
                  <option value="">Choose</option>
                  <option :value="country.id"
                          v-for="country in countries"
                          :key="'country_'+country.id">{{ country.name }}</option>
                </select>

                <v-errors :errors="errorFor('address.country_id')"></v-errors>
              </div>
            </div>
            <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('emergencyContact.house_no')}]">
              <label class="col-sm-4 col-form-label">บ้านเลขที่</label>
              <div class="col-sm-8">
                <input class="form-control form-control-sm"
                       v-model="emergencyContact.house_no"
                       type="text">

                <v-errors :errors="errorFor('emergencyContact.house_no')"></v-errors>
              </div>
            </div>
            <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('emergencyContact.village')}]">
              <label class="col-sm-4 col-form-label">หมู่บ้าน/อพาร์ทเมนท์/คอนโด</label>
              <div class="col-sm-8">
                <input class="form-control form-control-sm"
                       v-model="emergencyContact.village"
                       type="text">

                <v-errors :errors="errorFor('emergencyContact.village')"></v-errors>
              </div>
            </div>
            <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('emergencyContact.group')}]">
              <label class="col-sm-4 col-form-label">หมู่</label>
              <div class="col-sm-8">
                <input class="form-control form-control-sm"
                       v-model="emergencyContact.group"
                       type="text">

                <v-errors :errors="errorFor('emergencyContact.group')"></v-errors>
              </div>
            </div>
            <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('emergencyContact.alley')}]">
              <label class="col-sm-4 col-form-label">ซอย</label>
              <div class="col-sm-8">
                <input class="form-control form-control-sm"
                       v-model="emergencyContact.alley"
                       type="text">

                <v-errors :errors="errorFor('emergencyContact.alley')"></v-errors>
              </div>
            </div>
            <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('emergencyContact.road')}]">
              <label class="col-sm-4 col-form-label">ถนน</label>
              <div class="col-sm-8">
                <input class="form-control form-control-sm"
                       v-model="emergencyContact.road"
                       type="text">

                <v-errors :errors="errorFor('emergencyContact.road')"></v-errors>
              </div>
            </div>
            <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('emergencyContact.province')}]">
              <label class="col-sm-4 col-form-label">จังหวัด</label>
              <div class="col-sm-8">
                <input class="form-control form-control-sm"
                       v-model="emergencyContact.province"
                       type="text">

                <v-errors :errors="errorFor('emergencyContact.province')"></v-errors>
              </div>
            </div>
            <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('emergencyContact.district')}]">
              <label class="col-sm-4 col-form-label">เขต/อำเภอ</label>
              <div class="col-sm-8">
                <input class="form-control form-control-sm"
                       v-model="emergencyContact.district"
                       type="text">

                <v-errors :errors="errorFor('emergencyContact.district')"></v-errors>
              </div>
            </div>
            <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('emergencyContact.sub_district')}]">
              <label class="col-sm-4 col-form-label">แขวง/ตำบล</label>
              <div class="col-sm-8">
                <input class="form-control form-control-sm"
                       v-model="emergencyContact.sub_district"
                       type="text">

                <v-errors :errors="errorFor('emergencyContact.sub_district')"></v-errors>
              </div>
            </div>
            <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('emergencyContact.zip_code')}]">
              <label class="col-sm-4 col-form-label">รหัสไปรษณีย์</label>
              <div class="col-sm-8">
                <input class="form-control form-control-sm"
                       v-model="emergencyContact.zip_code"
                       type="text">

                <v-errors :errors="errorFor('emergencyContact.zip_code')"></v-errors>
              </div>
            </div>
            <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('emergencyContact.phone')}]">
              <label class="col-sm-4 col-form-label">โทรศัพท์ (บ้าน)</label>
              <div class="col-sm-8">
                <input class="form-control form-control-sm"
                       v-model="emergencyContact.phone"
                       type="text">

                <v-errors :errors="errorFor('emergencyContact.phone')"></v-errors>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.box-body -->
    </div>

    <button class="btn btn-primary-light btn-sm"
            @click="addEmergencyContact">+ Add Emergency Contact</button>
  </template>

  <div class="box" v-else>
    <div class="box-header small-box-header with-border">
      <h4 class="box-title">ผู้ติดต่อฉุกเฉิน</h4>
    </div>
    <div class="box-body">
      <div class="row">
        <div class="col-md-12 text-center">
          <i class="fas fa-user-plus fa-5x text-lighter mb-3"></i>
          <h5 class="m-0">ยังไม่มีผู้ติดต่อฉุกเฉิน</h5>
          <p class="text-muted m-0">กดที่ปุ่มด้านล่างเพิ่มเพิ่มผู้ติดต่อฉุกเฉิน</p>
          <button class="btn btn-primary-light btn-outline mt-3"
                  @click="addEmergencyContact">
            <i class="fas fa-plus"></i> Add Emergency Contact
          </button>
        </div>
      </div>
    </div>
    <!-- /.box-body -->
  </div>
</template>

<script>
export default {
  props: ['formData', 'errors'],
  data() {
    return {
      showForm: false,
      prefixes: [],
      countries: [],
      relationships: [],
      form: {}
    }
  },
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  watch: {
    errors: {
      handler(newValue) {
        this.allErrors = newValue;
      },
      deep: true
    },
    formData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true
    }
  },
  created() {
    this.loadPrefixes();
    this.loadCountries();
    this.loadRelationships();
  },
  methods: {
    addEmergencyContact() {
      this.form.emergencyContacts.push({
        name_prefix_id: '',
        name: '',
        relationship_id: '',
        relationship_other: '',
        mobile_country_code: '',
        mobile: '',
        mobile_alternative: '',
        email: '',
        country_id: '',
        house_no: '',
        village: '',
        group: '',
        alley: '',
        road: '',
        province: '',
        district: '',
        sub_district: '',
        zip_code: '',
        phone: '',
      })
    },
    removeEmergencyContact(i) {
      this.form.emergencyContacts.splice(i, 1);
    },
    loadPrefixes() {
      this.prefixes = [];

      this.axios.get('/name-prefixes')
        .then(res => {
          this.prefixes = res.data.data;
        });
    },
    loadCountries() {
      this.countries = [];

      this.axios.get('/countries')
        .then(res => {
          this.countries = res.data.data;
        });
    },
    loadRelationships() {
      this.relationships = [];

      this.axios.get('/relationships')
        .then(res => {
          this.relationships = res.data.data;
        });
    }
  }
}
</script>