<template>
  <div class="box">
    <div class="box-header small-box-header with-border">
      <h4 class="box-title">ที่อยู่ปัจจุบัน</h4>
    </div>
    <div class="box-body" v-if="form && form.address">
      <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('address.house_no')}]">
        <label class="col-sm-3 col-form-label">บ้านเลขที่</label>
        <div class="col-sm-9">
          <input class="form-control form-control-sm"
                 v-model="form.address.house_no"
                 type="text">

          <v-errors :errors="errorFor('address.house_no')"></v-errors>
        </div>
      </div>
      <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('address.village')}]">
        <label class="col-sm-3 col-form-label">หมู่บ้าน/อพาร์ทเมนท์/คอนโด</label>
        <div class="col-sm-9">
          <input class="form-control form-control-sm"
                 v-model="form.address.village"
                 type="text">

          <v-errors :errors="errorFor('address.village')"></v-errors>
        </div>
      </div>
      <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('address.group')}]">
        <label class="col-sm-3 col-form-label">หมู่</label>
        <div class="col-sm-9">
          <input class="form-control form-control-sm"
                 v-model="form.address.group"
                 type="text">

          <v-errors :errors="errorFor('address.group')"></v-errors>
        </div>
      </div>
      <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('address.alley')}]">
        <label class="col-sm-3 col-form-label">ซอย</label>
        <div class="col-sm-9">
          <input class="form-control form-control-sm"
                 v-model="form.address.alley"
                 type="text">

          <v-errors :errors="errorFor('address.alley')"></v-errors>
        </div>
      </div>
      <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('address.road')}]">
        <label class="col-sm-3 col-form-label">ถนน</label>
        <div class="col-sm-9">
          <input class="form-control form-control-sm"
                 v-model="form.address.road"
                 type="text">

          <v-errors :errors="errorFor('address.road')"></v-errors>
        </div>
      </div>
      <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('address.district')}]">
        <label class="col-sm-3 col-form-label">เขต/อำเภอ</label>
        <div class="col-sm-9">
          <input class="form-control form-control-sm"
                 v-model="form.address.district"
                 type="text">

          <v-errors :errors="errorFor('address.district')"></v-errors>
        </div>
      </div>
      <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('address.sub_district')}]">
        <label class="col-sm-3 col-form-label">แขวง/ตำบล</label>
        <div class="col-sm-9">
          <input class="form-control form-control-sm"
                 v-model="form.address.sub_district"
                 type="text">

          <v-errors :errors="errorFor('address.sub_district')"></v-errors>
        </div>
      </div>
      <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('address.province')}]">
        <label class="col-sm-3 col-form-label">จังหวัด</label>
        <div class="col-sm-9">
          <input class="form-control form-control-sm"
                 v-model="form.address.province"
                 type="text">

          <v-errors :errors="errorFor('address.province')"></v-errors>
        </div>
      </div>
      <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('address.zip_code')}]">
        <label class="col-sm-3 col-form-label">รหัสไปรษณีย์</label>
        <div class="col-sm-9">
          <input class="form-control form-control-sm"
                 v-model="form.address.zip_code"
                 type="text">

          <v-errors :errors="errorFor('address.zip_code')"></v-errors>
        </div>
      </div>
      <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('address.country_id')}]">
        <label class="col-sm-3 col-form-label">ประเทศ</label>
        <div class="col-sm-9">
          <select class="form-select form-select-sm"
                  v-model="form.address.country_id">
            <option value="">Choose</option>
            <option :value="country.id"
                    v-for="country in countries"
                    :key="'country_'+country.id">{{ country.name }}</option>
          </select>

          <v-errors :errors="errorFor('address.country_id')"></v-errors>
        </div>
      </div>
      <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('address.phone')}]">
        <label class="col-sm-3 col-form-label">โทรศัพท์ (บ้าน)</label>
        <div class="col-sm-9">
          <input class="form-control form-control-sm"
                 v-model="form.address.phone"
                 type="text">

          <v-errors :errors="errorFor('address.phone')"></v-errors>
        </div>
      </div>
    </div>
    <!-- /.box-body -->
  </div>
</template>

<script>
export default {
  props: ['formData', 'errors'],
  data() {
    return {
      countries: [],
      form: {}
    }
  },
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  watch: {
    errors: {
      handler(newValue) {
        this.allErrors = newValue;
      },
      deep: true
    },
    formData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true
    }
  },
  created() {
    this.loadCountries();
  },
  methods: {
    loadCountries() {
      this.countries = [];

      this.axios.get('/countries')
        .then(res => {
          this.countries = res.data.data;
        });
    },
  }
}
</script>