<template>
  <div class="table-responsive-sm">
    <table class="table table-small">
      <thead>
      <tr>
        <th>ลำดับ</th>
        <th>รหัสสินค้า</th>
        <th>ชื่อสินค้า</th>
        <th>ราคา</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, i) in caseObj.consumes" :key="'item_'+item.id">
        <td>{{ i+1 }}</td>
        <td>{{ item.product.code }}</td>
        <td>{{ item.product.name_sale }}</td>
        <td>{{ item.product.selling_price }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: ['caseObj']
}
</script>