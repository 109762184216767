<template>
  <div class="row mb-20">
    <div class="col-4">
      <div class="box" style="height: 100%">
        <div class="box-header small-box-header with-border">
          <h4 class="box-title">ข้อมูลลูกค้า</h4>
        </div>
        <div class="box-body">
          <div class="text-center">
            <img class="max-h-200"
                 onerror="this.src='/images/avatar-girl.png'"
                 :src="form.image ? form.image : '/images/avatar-girl.png'" > <br>

            <input class="d-none"
                   type="file"
                   @change="readURL($event.target)"
                   ref="imageInput"
                   accept="image/*">

            <button type="button"
                    @click.prevent="chooseImage"
                    class="btn-sm mt-5 waves-effect waves-light btn btn-outline btn-primary">
              <i class="far fa-image"></i> เปลี่ยนรูปโปรไฟล์
            </button>
          </div>

          <table class="table table-borderless patient-details-table mt-10">
            <tr v-if="form.patient_id">
              <td>รหัสลูกค้า</td>
              <td>: {{ form.patient_id }}</td>
            </tr>
            <tr v-if="form.hn">
              <td>HN</td>
              <td>: {{ form.hn }}</td>
            </tr>
            <tr>
              <td>การใช้งาน</td>
              <td>
                <div class="">
                  <input name="patient_status"
                         type="radio"
                         v-model="form.status"
                         id="patient_active" value="1">
                  <label for="patient_active" class="me-30">เปิด</label>
                  <input name="patient_status"
                         type="radio"
                         v-model="form.status"
                         id="patient_inactive" value="0">
                  <label for="patient_inactive" class="me-30">ปิด</label>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <!-- /.box-body -->
      </div>
    </div>
    <div class="col-8">
      <div class="box" style="height: 100%">
        <div class="box-header small-box-header with-border">
          <h4 class="box-title">ข้อมูลส่วนตัว</h4>
        </div>
        <div class="box-body">
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('name_prefix_id')}]">
            <label class="col-sm-4 col-form-label">คำนำหน้า <span class="text-danger">*</span></label>
            <div class="col-sm-8">
              <select class="form-select form-select-sm"
                      v-model="form.name_prefix_id">
                <option value="">Select Option</option>
                <option :value="prefix.id"
                        v-for="prefix in prefixes"
                        :key="'prefix_'+prefix.id">{{ prefix.prefix }}</option>
              </select>

              <v-errors :errors="errorFor('name_prefix_id')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('first_name')}]">
            <label class="col-sm-4 col-form-label">ชื่อลูกค้า <span class="text-danger">*</span></label>
            <div class="col-sm-4">
              <input class="form-control form-control-sm"
                     v-model="form.first_name"
                     type="text">

              <v-errors :errors="errorFor('first_name')"></v-errors>
            </div>
            <div class="col-sm-4">
              <input class="form-control form-control-sm"
                     v-model="form.last_name"
                     type="text">

              <v-errors :errors="errorFor('last_name')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('nick_name')}]">
            <label class="col-sm-4 col-form-label">ชื่อเล่น</label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     v-model="form.nick_name"
                     type="text">

              <v-errors :errors="errorFor('nick_name')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('gender')}]">
            <label class="col-sm-4 col-form-label">เพศ</label>
            <div class="col-sm-8">
              <select class="form-select form-select-sm"
                      v-model="form.gender">
                <option value="">Select Option</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>

              <v-errors :errors="errorFor('gender')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('dob')}]">
            <label class="col-sm-4 col-form-label">วันเกิด <span class="text-danger">*</span></label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     v-model="form.dob"
                     type="date">

              <v-errors :errors="errorFor('dob')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('nationality')}]">
            <label class="col-sm-4 col-form-label">สัญชาติ</label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     v-model="form.nationality"
                     type="text">

              <v-errors :errors="errorFor('nationality')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('id_card_number')}]">
            <label class="col-sm-4 col-form-label">หมายเลขบัตรประจำตัวประชาชน</label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     v-model="form.id_card_number"
                     type="text">

              <v-errors :errors="errorFor('id_card_number')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('passport_number')}]">
            <label class="col-sm-4 col-form-label">หมายเลขหนังสือเดินทาง</label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     v-model="form.passport_number"
                     type="text">

              <v-errors :errors="errorFor('passport_number')"></v-errors>
            </div>
          </div>
        </div>
        <!-- /.box-body -->
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="box">
        <div class="box-header small-box-header with-border">
          <h4 class="box-title">ข้อมูลอื่น ๆ</h4>
        </div>
        <div class="box-body">
          <div class="row">
            <div class="col-6">
              <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('occupation')}]">
                <label class="col-sm-4 col-form-label">อาชีพ</label>
                <div class="col-sm-8">
                  <input class="form-control form-control-sm"
                         v-model="form.occupation"
                         type="text">

                  <v-errors :errors="errorFor('occupation')"></v-errors>
                </div>
              </div>
              <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('occupation_other')}]">
                <label class="col-sm-4 col-form-label">อาชีพ (อื่น)</label>
                <div class="col-sm-8">
                  <input class="form-control form-control-sm"
                         v-model="form.occupation_other"
                         type="text">

                  <v-errors :errors="errorFor('occupation_other')"></v-errors>
                </div>
              </div>
              <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('country_id')}]">
                <label class="col-sm-4 col-form-label">รหัสประเทศ (มือถือ)</label>
                <div class="col-sm-8">
                  <select class="form-select form-select-sm" v-model="form.country_id">
                    <option value="">Choose</option>
                    <option :value="country.id"
                            v-for="country in countries"
                            :key="'country_'+country.id">{{ country.name }}</option>
                  </select>

                  <v-errors :errors="errorFor('country_id')"></v-errors>
                </div>
              </div>
              <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('mobile')}]">
                <label class="col-sm-4 col-form-label">เบอร์มือถือ <span class="text-danger">*</span></label>
                <div class="col-sm-8">
                  <input class="form-control form-control-sm"
                         v-model="form.mobile"
                         type="text">

                  <v-errors :errors="errorFor('mobile')"></v-errors>
                </div>
              </div>
              <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('mobile_alternative')}]">
                <label class="col-sm-4 col-form-label">เบอร์มือถือ (สำรอง)</label>
                <div class="col-sm-8">
                  <input class="form-control form-control-sm"
                         v-model="form.mobile_alternative"
                         type="text">

                  <v-errors :errors="errorFor('mobile_alternative')"></v-errors>
                </div>
              </div>
              <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('email')}]">
                <label class="col-sm-4 col-form-label">อีเมล</label>
                <div class="col-sm-8">
                  <input class="form-control form-control-sm"
                         v-model="form.email"
                         type="text">

                  <v-errors :errors="errorFor('email')"></v-errors>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('blood_group_id')}]">
                <label class="col-sm-4 col-form-label">หมู่เลือด <span class="text-danger">*</span></label>
                <div class="col-sm-8">
                  <select class="form-select form-select-sm"
                          v-model="form.blood_group_id">
                    <option value="">Select Option</option>
                    <option :value="group.id"
                            v-for="group in bloodGroups"
                            :key="'blood_group_'+group.id">{{ group.name }}</option>
                  </select>

                  <v-errors :errors="errorFor('blood_group_id')"></v-errors>
                </div>
              </div>
              <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('congenital_disease')}]">
                <label class="col-sm-4 col-form-label">โรคประจำตัว</label>
                <div class="col-sm-8">
                  <textarea rows="4"
                            class="form-control form-control-sm mb-2"
                            v-model="form.congenital_disease" />
                  <v-errors :errors="errorFor('congenital_disease')"></v-errors>
                </div>
              </div>
              <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('drug_allergy')}]">
                <label class="col-sm-4 col-form-label">การแพ้ยา</label>
                <div class="col-sm-8">
                  <textarea rows="4"
                            class="form-control form-control-sm mb-2"
                            v-model="form.drug_allergy" />
                  <v-errors :errors="errorFor('drug_allergy')"></v-errors>
                </div>
              </div>
              <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('note')}]">
                <label class="col-sm-4 col-form-label">หมายเหตุ</label>
                <div class="col-sm-8">
                  <textarea rows="4"
                            class="form-control form-control-sm"
                            v-model="form.note" />
                  <v-errors :errors="errorFor('note')"></v-errors>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /.box-body -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['formData', 'errors'],
  data() {
    return {
      prefixes: [],
      countries: [],
      bloodGroups: [],
      form: {}
    }
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  watch: {
    errors: {
      handler(newValue) {
        this.allErrors = newValue;
      },
      deep: true
    },
    formData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true
    }
  },
  created() {
    this.loadPrefixes();
    this.loadCountries();
    this.loadBloodGroups();
  },
  methods: {
    loadPrefixes() {
      this.prefixes = [];

      this.axios.get('/name-prefixes')
        .then(res => {
          this.prefixes = res.data.data;
        })
    },
    loadCountries() {
      this.countries = [];

      this.axios.get('/countries')
        .then(res => {
          this.countries = res.data.data;
        });
    },
    loadBloodGroups() {
      this.bloodGroups = [];

      this.axios.get('/blood-groups')
        .then(res => {
          this.bloodGroups = res.data.data;
        });
    },
    chooseImage() {
      this.$refs.imageInput.click();
    },
    readURL(input) {
      let self = this;

      if (input.files && input.files[0]) {
        let reader = new FileReader();

        reader.onload = function (e) {
          self.form.image = e.target.result;
        }

        reader.readAsDataURL(input.files[0]);
      }
    },
  }
}
</script>