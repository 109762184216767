<template>
  <div class="box" v-if="patient">
    <div class="box-body text-end min-h-150 profile-details-gradient-background">
    </div>
    <div class="box-body wed-up position-relative">
      <div class="d-md-flex align-items-center">
        <div class=" me-20 text-center text-md-start">
          <img :src="patient.image ? patient.image : '/images/avatar-girl.png'"
               onerror="this.src='/images/avatar-girl.png'"
               class="bg-success-light rounded10 h-180" />
          <div class="text-center my-10">
          </div>
        </div>
        <div class="mt-80">
          <h4 class="fw-600 mb-5 me-5">
            {{ patient.full_name }}

            <button class="btn btn-primary btn-sm"
                    @click="$refs.editModal.show(patient)">
              <i class="fas fa-edit"></i>
            </button>
          </h4>
          <h5 class="fw-500 mb-5">{{ patient.hn }}</h5>
          <p><i class="fa fa-clock-o"></i> {{ patient.updatedBy.full_name }} on {{ patient.updated_at }}</p>
        </div>
      </div>
    </div>
    <div class="box-body pt-0">
      <div class="row">
        <div class="col-3">
          <h4>{{ $t('patients.profile') }}</h4>
          <table class="patient-details-table table table-borderless">
            <tr>
              <th>{{ $t('patients.customer_id') }}</th>
              <td>{{ patient.patient_id }}</td>
            </tr>
            <tr>
              <th>{{ $t('patients.hn_number') }}</th>
              <td>{{ patient.hn }}</td>
            </tr>
            <tr>
              <th class="pt-3">{{ $t('patients.prefix') }}</th>
              <td class="pt-3">{{ patient.namePrefix ? patient.namePrefix.prefix : '' }}</td>
            </tr>
            <tr>
              <th>{{ $t('patients.first_name') }}</th>
              <td>{{ patient.first_name }}</td>
            </tr>
            <tr>
              <th>{{ $t('patients.last_name') }}</th>
              <td>{{ patient.last_name }}</td>
            </tr>
            <tr>
              <th>{{ $t('patients.nick_name') }}</th>
              <td>{{ patient.nick_name }}</td>
            </tr>
            <tr>
              <th>{{ $t('patients.gen') }}</th>
              <td>{{ patient.gender }}</td>
            </tr>
            <tr>
              <th class="pt-3">{{ $t('patients.birthday') }}</th>
              <td class="pt-3">{{ patient.dob_format }}</td>
            </tr>
            <tr>
              <th>{{ $t('patients.age') }}</th>
              <td>{{ patient.age }}</td>
            </tr>
            <tr>
              <th class="pt-3">{{ $t('patients.occupation') }}</th>
              <td class="pt-3">{{ patient.occupation }}</td>
            </tr>
            <tr>
              <th>({{ $t('patients.other') }})</th>
              <td>{{ patient.occupation_other }}</td>
            </tr>
            <tr>
              <th class="pt-3">{{ $t('patients.nationality') }}</th>
              <td class="pt-3">th{{ patient.nationality }}</td>
            </tr>
            <tr>
              <th>{{ $t('patients.national_id') }}</th>
              <td>{{ patient.id_card_number }}</td>
            </tr>
            <tr>
              <th>{{ $t('patients.passport_no') }}.</th>
              <td>{{ patient.passport_number }}</td>
            </tr>
            <tr>
              <th class="pt-3">{{ $t('patients.blood_type') }}</th>
              <td class="pt-3">{{ patient.bloodGroup ? patient.bloodGroup.name : '' }}</td>
            </tr>
          </table>
        </div>
        <div class="col-3">
          <h4>{{ $t('patients.contact') }}</h4>
          <table class="patient-details-table table table-borderless">
            <tr>
              <th>{{ $t('patients.country') }}</th>
              <td>{{ patient.country ? patient.country.name : '' }}</td>
            </tr>
            <tr>
              <th>{{ $t('patients.phone') }}</th>
              <td>{{ patient.mobile }}</td>
            </tr>
            <tr>
              <th>{{ $t('patients.backup_phone') }}</th>
              <td>{{ patient.mobile_alternative }}</td>
            </tr>
            <tr>
              <th>{{ $t('patients.email') }}</th>
              <td>{{ patient.email }}</td>
            </tr>
          </table>
          <h4>{{ $t('patients.address') }}</h4>
          <table class="patient-details-table table table-borderless">
            <tr>
              <th>{{ $t('patients.country') }}</th>
              <td>{{ patient.address && patient.address.country ? patient.address.country.name : '' }}</td>
            </tr>
            <tr>
              <th>{{ $t('patients.street') }}</th>
              <td>{{ patient.address ? patient.address.street : '' }}</td>
            </tr>
            <tr>
              <th>{{ $t('patients.street_2') }}</th>
              <td>{{ patient.address ? patient.address.street2 : '' }}</td>
            </tr>
            <tr>
              <th>{{ $t('patients.district') }}</th>
              <td>{{ patient.address ? patient.address.sub_district : '' }}</td>
            </tr>
            <tr>
              <th>{{ $t('patients.province') }}</th>
              <td>{{ patient.address ? patient.address.province : '' }}</td>
            </tr>
            <tr>
              <th>{{ $t('patients.zip') }}</th>
              <td>{{ patient.address ? patient.address.zip_code : '' }}</td>
            </tr>
            <tr>
              <th>{{ $t('patients.phone_home') }}</th>
              <td>{{ patient.address ? patient.address.phone : '' }}</td>
            </tr>
          </table>
        </div>
        <div class="col-3">
          <h4>{{ $t('patients.emergency_contact') }}</h4>
          <table class="patient-details-ta table table-borderless"
                 v-for="contact in patient.emergencyContacts"
                 :key="'contact_'+contact.id">
            <tr>
              <th>{{ $t('patients.name') }}</th>
              <td>{{ contact.name }}</td>
            </tr>
            <tr>
              <th>{{ $t('patients.relation') }}</th>
              <td>{{ contact.relationship ? contact.relationship.name : '' }}</td>
            </tr>
            <tr>
              <th>({{ $t('patients.other') }})</th>
              <td>{{ contact.relationship_other }}</td>
            </tr>
            <tr>
              <th>{{ $t('patients.country') }}</th>
              <td>{{ contact.country ? contact.country.name : '' }}</td>
            </tr>
            <tr>
              <th>{{ $t('patients.phone') }}</th>
              <td>{{ contact.mobile }}</td>
            </tr>
            <tr>
              <th>{{ $t('patients.email') }}</th>
              <td>{{ contact.email }}</td>
            </tr>
            <tr>
              <th>{{ $t('patients.address') }}</th>
              <td></td>
            </tr>
          </table>
        </div>
        <div class="col-3">
          <template v-if="patient.congenital_disease">
            <h4>{{ $t('patients.congenital_disease') }}</h4>
            <p>{{ patient.congenital_disease }}</p>
          </template>

          <template v-if="patient.drug_allergy">
            <h4>{{ $t('patients.medical_allergies') }}</h4>
            <p>{{ patient.drug_allergy }}</p>
          </template>

          <template v-if="patient.note">
            <h4>{{ $t('patients.note') }}</h4>
            <p>{{ patient.note }}</p>
          </template>
        </div>
      </div>
    </div>
  </div>

  <form-modal ref="editModal"
              @added="$store.dispatch('patientModule/loadPatient', this.$route.params.id);"></form-modal>
</template>

<script>
import {mapGetters} from "vuex";
import FormModal from "@/views/patient/FormModal";

export default {
  components: {FormModal},
  computed: {
    ...mapGetters({
      patient: 'patientModule/getPatient'
    })
  }
}
</script>