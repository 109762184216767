<template>
  <div class="row">
    <div class="col-md-6">
      <div class="box">
        <div class="box-header with-border small-box-header">
          <h4 class="box-title">พนักงาน</h4>
        </div>
        <div class="box-body">
          <div class="d-flex align-items-center mb-3">
            <div class="me-15">
              <img src="/images/avatar-3.png" class="avatar avatar-lg rounded10">
            </div>
            <div class="d-flex flex-column flex-grow-1 fw-500">
              <a href="#" class="text-dark hover-primary mb-1 fs-16">นวพัธร เสือแก้ว</a>
              <span class="text-fade">พนักงาน</span>
            </div>
          </div>
          <div class="d-flex align-items-center mb-3">
            <div class="me-15">
              <img src="/images/avatar-3.png" class="avatar avatar-lg rounded10">
            </div>
            <div class="d-flex flex-column flex-grow-1 fw-500">
              <a href="#" class="text-dark hover-primary mb-1 fs-16">จงกลวรรณ สุวรรณชาตรี</a>
              <span class="text-fade">พนักงาน</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="box">
        <div class="box-header with-border small-box-header">
          <h4 class="box-title">หมอ</h4>
        </div>
        <div class="box-body">
          <div class="d-flex align-items-center mb-3">
            <div class="me-15">
              <img src="/images/avatar-2.png" class="avatar avatar-lg rounded10">
            </div>
            <div class="d-flex flex-column flex-grow-1 fw-500">
              <a href="#" class="text-dark hover-primary mb-1 fs-16">Dr. Carla Schleifer</a>
              <span class="text-fade">หมอ</span>
            </div>
          </div>
          <div class="d-flex align-items-center mb-3">
            <div class="me-15">
              <img src="/images/avatar-4.png" class="avatar avatar-lg rounded10">
            </div>
            <div class="d-flex flex-column flex-grow-1 fw-500">
              <a href="#" class="text-dark hover-primary mb-1 fs-16">Dr. Hanna Geidt</a>
              <span class="text-fade">หมอดมยา</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>