<template>
  <div class="row mt-3">
    <div class="col-md-3">
      <div class="box">
        <div class="box-header with-border small-box-header">
          <h4 class="box-title">Case</h4>
        </div>
        <div class="box-body p-0" v-if="visit">
          <div :class="['d-flex align-items-center p-10', {'bg-primary-light': caseObj.id === currentCaseId}]"
               v-for="caseObj in visit.cases"
               :key="'case_'+caseObj.id">
            <div class="me-15">
              <img :src="caseObj.doctor.image ? caseObj.doctor.image : '/images/avatar-girl.png'"
                   onerror="this.src='/images/avatar-girl.png'"
                   class="avatar avatar-lg rounded10 bg-primary-light">
            </div>
            <div class="d-flex flex-column flex-grow-1 fw-500">
              <p class="mb-0">{{ caseObj.case_no }}</p>
              <span class="text-muted">{{ caseObj.doctor.full_name }}</span>
            </div>
            <div>
              <button type="button"
                      @click="loadCase(caseObj)"
                      class="waves-effect waves-light btn btn-info-light"><i class="fas fa-arrow-right"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-9" v-if="caseObj">
      <button :class="['btn btn-sm me-2', {'btn-primary': currentTabComponent === 'services'}, {'btn-secondary': currentTabComponent !== 'services'}]"
              @click="currentTabComponent='services'">Services</button>
      <button :class="['btn btn-sm me-2', {'btn-primary': currentTabComponent === 'notes'}, {'btn-secondary': currentTabComponent !== 'notes'}]"
              @click="currentTabComponent='notes'">Notes</button>
      <button :class="['btn btn-sm me-2', {'btn-primary': currentTabComponent === 'consumable'}, {'btn-secondary': currentTabComponent !== 'consumable'}]"
              @click="currentTabComponent='consumable'">Consumable</button>
      <button :class="['btn btn-sm me-2', {'btn-primary': currentTabComponent === 'staff'}, {'btn-secondary': currentTabComponent !== 'staff'}]"
              @click="currentTabComponent='staff'">Staff</button>

      <hr>

      <component :caseObj="caseObj" v-bind:is="currentTabComponent"></component>
    </div>
  </div>
</template>

<script>
import Consumable from './caseTabs/Consumable';
import Notes from './caseTabs/Notes';
import Anesthetist from './caseTabs/Anesthetist';
import Staff from './caseTabs/Staff';
import Services from './caseTabs/Services';

export default {
  components: {Consumable, Notes, Anesthetist, Staff, Services},
  props: ['visit'],
  data() {
    return {
      currentTabComponent: 'services',
      currentCaseId: '',
      caseObj: null,
    }
  },
  created() {
    this.loadCase(this.visit.cases[0])
  },
  watch: {
    visit() {
      this.caseObj = null;
      this.currentTabComponent = 'services'

      this.loadCase(this.visit.cases[0])
    }
  },
  methods: {
    loadCase(caseObj) {
      this.currentCaseId = caseObj.id;

      this.axios.get('/visit-cases/' + caseObj.id)
        .then(res => {
          this.caseObj = res.data.data;
        })
    }
  }
}
</script>