<template>
  <div class="row">
    <div class="col-md-6" v-if="!caseObj.portraits.length">
      <div class="alert alert-danger">No Portraits Found</div>
    </div>

    <template v-else>
      <div class="col-md-4">
        <img class="img-thumbnail rounded"
             v-if="currentImage"
             :src="currentImage.image"
             style="width: 100%">
      </div>
      <div class="col-md-2">
        <div class="slim-scroll">
          <img :class="['img-thumbnail rounded mb-2', {'bg-primary': image.id === currentImage.id}]"
               v-for="image in caseObj.portraits"
               :key="'image_'+image.id"
               @click="currentImage = image"
               :src="image.image"
               style="width: 90%">
        </div>
      </div>
    </template>

    <div class="col-md-6">
      <div class="slim-scroll">
        <div class="form-group"
             v-for="note in notes"
             :key="'note_'+note.id">
          <label class="form-label">{{ note.name }}</label>
          <textarea rows="4"
                    class="form-control"
                    v-model="note.description" disabled></textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['caseObj'],
  data() {
    return {
      currentImage: this.caseObj.portraits.length ? this.caseObj.portraits[0] : null,
      notes: [
        {
          id: 1,
          name: 'Subjective',
          description: this.caseObj.note_subjective
        },
        {
          id: 2,
          name: 'Objective',
          description: this.caseObj.note_objective
        },
        {
          id: 3,
          name: 'Assessment',
          description: this.caseObj.note_assessment
        },
        {
          id: 4,
          name: 'Plan',
          description: this.caseObj.note_plan
        },
        {
          id: 5,
          name: 'Diagnosis',
          description: this.caseObj.note_diagnosis
        }
      ]
    }
  },
  mounted() {
    this.initSlimScroll()
  },
  methods: {
    initSlimScroll() {
      window.$('.slim-scroll').slimScroll({
        height: '400px'
      });
    }
  }
}
</script>