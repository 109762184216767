<template>
  <div class="box">
    <div class="box-header with-border">
      <div class="row">
        <div class="col-6">
          <h4 class="box-title">Documents</h4>
        </div>
        <div class="col-6 text-end">
          <button class="btn btn-sm btn-info-light"
                  @click="$refs.documentInput.click()">
            <i class="fas fa-upload"></i> อัปโหลด
          </button>
        </div>
      </div>
    </div>
    <div class="box-body">
      <table class="table table-hover">
        <thead>
        <tr>
          <th>File name</th>
          <th>Case</th>
          <th>Visit</th>
          <th>Upload date</th>
        </tr>
        </thead>

        <tbody>
        <tr v-for="document in documents"
            :key="'document_'+document.id">
          <td>
            <a :href="document.file" target="_blank">{{ document.original_filename }}</a>
          </td>
          <td>{{ document.visitCase ? document.visitCase.case_no : '' }}</td>
          <td>{{ document.visit ? document.visit.visit_no : '' }}</td>
          <td>{{ document.created_at_format }}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <!-- /.box-body -->
  </div>

  <input @change="readDocumentURL($event.target)"
         type="file"
         ref="documentInput" style="display: none">
</template>

<script>
export default {
  data() {
    return {
      documents: []
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.documents = [];

      this.axios.get('/patients/documents/' + this.$route.params.id)
        .then(res => {
          this.documents = res.data.data;
        })
    },
    readDocumentURL(input) {
      if (input.files && input.files[0]) {
        let formData = new FormData();

        formData.append('file', input.files[0]);
        formData.append('patient_id', this.$route.params.id);
        formData.append('original_filename',  input.files[0].name);

        this.axios.post('/visits/document', formData)
          .then((res) => {
            this.documents = res.data.data;
          });
      }
    }
  }
}
</script>