<template>
  <div class="modal fade bs-example-modal-lg"
       id="patient-form-modal"
       tabindex="-1"
       role="dialog"
       aria-labelledby="myLargeModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-lg modal-xl modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myLargeModalLabel">
            {{ title }} <br>
            <small class="text-muted" v-if="patient_id">
              Create Date: {{ form.created_at }}  |  Created by: {{ form.createdBy.full_name }}   |  Modified Date: {{ form.updated_at }}  |  Modified by: {{ form.updatedBy.full_name }}
            </small>
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row mb-3">
            <div class="col-md-12">
              <button :class="'btn btn-sm me-2 ' + (currentTabComponent === 'BasicInformation' ? 'btn-primary': 'btn-secondary')"
                      @click.prevent="currentTabComponent = 'BasicInformation'">
                ประวัติ
              </button>

              <button :class="'btn btn-sm me-2 ' + (currentTabComponent === 'AddressModal' ? 'btn-primary': 'btn-secondary')"
                      @click.prevent="currentTabComponent = 'AddressModal'">
                ที่อยู่ปัจจุบัน
              </button>

              <button :class="'btn btn-sm ' + (currentTabComponent === 'EmergencyContact' ? 'btn-primary': 'btn-secondary')"
                      @click.prevent="currentTabComponent = 'EmergencyContact'">
                ผู้ติดต่อฉุกเฉิน
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
                <component :formData="form"
                           :errors="allErrors"
                           :is="currentTabComponent"></component>
            </div>
          </div>
        </div>
        <div class="modal-footer text-center">
          <button type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal">ยกเลิกแก้ไขข้อมูล</button>

          <button class="btn btn-success"
                  @click="submit"
                  :disabled="loading">บันทึก</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
import AddressModal from './EditModalTabs/Address';
import EmergencyContact from './EditModalTabs/EmergencyContact';
import BasicInformation from './EditModalTabs/BasicInformation';
export default {
  components: {AddressModal, EmergencyContact, BasicInformation},
  data() {
    return {
      modal: null,
      allErrors: null,
      title: '',
      currentTabComponent: "BasicInformation",
      patient_id: '',
      form: {
        image: '',
        name_prefix_id: '',
        nick_name: '',
        first_name: '',
        last_name: '',
        gender: '',
        country_id: '',
        dob: '',
        nationality: '',
        id_card_number: '',
        passport_number: '',
        occupation: '',
        occupation_other: '',
        mobile: '',
        mobile_alternative: '',
        email: '',
        blood_group_id: '',
        congenital_disease: '',
        drug_allergy: '',
        note: '',
        status: '1',
        address: {
          country_id: '',
          house_no: '',
          village: '',
          group: '',
          alley: '',
          road: '',
          province: '',
          district: '',
          sub_district: '',
          zip_code: '',
          phone: '',
        },
        emergencyContacts: [],
      }
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('patient-form-modal'));
  },
  methods: {
    resetForm() {
      Object.keys(this.form).forEach(key => {
        if (key !== 'address') {
          this.form[key] = '';
        } else {
          Object.keys(this.form[key]).forEach(k => {
            this.form[key][k] = '';
          });
        }
      })

      this.form.emergencyContacts = [];
      this.form.status = '1';
      delete this.form.patient_id;
      delete this.form.hn;
    },
    show(patient = null) {
      console.log(patient);
      this.resetForm();

      if (patient) {
        this.form = {...this.form, ...patient};
        this.patient_id = patient.id;

        if (patient.addresses.length) {
          this.form.address = {...patient.addresses[0]};
          delete this.form.address.id;
        }

        /*if (this.form.emergencyContact) {
          this.form.emergencyContact = {...patient.emergencyContacts[0]};
          delete this.form.emergencyContacts.id;
          this.form.emergencyContact.show = true;
        }*/

        delete this.form.id;
        delete this.form.addresses;
        // delete this.form.emergencyContacts;

        this.title = 'แก้ไขข้อมูลผู้ใช้บริการ';
      } else {
        this.patient_id = '';
        this.title = 'เพิ่มข้อมูลผู้ใช้';
      }

      this.currentTabComponent = 'BasicInformation';
      this.modal.show();
    },
    submit() {
      this.allErrors = null;
      this.loading = true;

      let url = '/patients';

      if (this.patient_id) {
        url = '/patients/' + this.patient_id
        this.form._method = 'PATCH'
      } else {
        delete this.form._method;
      }

      this.axios.post(url, this.form)
        .then(() => {
          this.resetForm();
          this.modal.hide();
          this.$emit('added');
        })
        .catch((err) => {
          this.allErrors = err.response.data.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  }
}
</script>