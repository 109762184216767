<template>
  <div class="modal fade bs-example-modal-lg"
       id="service-details-modal"
       tabindex="-1"
       role="dialog"
       aria-labelledby="myLargeModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-lg modal-xl modal-dialog-scrollable">
      <div class="modal-content" v-if="visit">
        <div class="modal-header">
          <h4 class="modal-title" id="myLargeModalLabel">
            {{ visit.visit_no }}<br>
            <small class="text-muted">{{ visit.created_at_format }}</small>
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <button :class="['btn btn-sm me-2', {'btn-primary': currentTabComponent === 'examination'}, {'btn-secondary': currentTabComponent !== 'examination'}]"
                  @click="currentTabComponent='examination'">ข้อมูลการตรวจร่างกาย</button>
          <button :class="['btn btn-sm', {'btn-primary': currentTabComponent === 'case-template'}, {'btn-secondary': currentTabComponent !== 'case-template'}]"
                  @click="currentTabComponent='case-template'">Case</button>

          <component :visit="visit" v-bind:is="currentTabComponent"></component>
        </div>
        <div class="modal-footer text-center">
          <button type="button"
                  data-bs-dismiss="modal"
                  class="btn btn-danger">ปิด</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
import Examination from './ServiceDetailsModal/Examination';
import CaseTemplate from './ServiceDetailsModal/CaseTemplate';

export default {
  components: {Examination, CaseTemplate},
  data() {
    return {
      modal: null,
      visit: null,
      currentTabComponent: 'examination'
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('service-details-modal'));
  },
  methods: {
    show(visit) {
      this.visit = visit;

      this.modal.show();
    }
  }
}
</script>