<template>
  <div class="content-header">
    <div class="d-flex align-items-center">
      <div class="me-auto">
        <h3 class="page-title">{{ $t('patients.patient_details') }}</h3>
        <div class="d-inline-block align-items-center">
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#"><i class="mdi mdi-home-outline"></i></a></li>
              <li class="breadcrumb-item active" aria-current="page">{{ $t('patients.patient_details') }}</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>

  <div class="row" v-if="patient">
    <div class="col-6">
      <router-link :class="['waves-effect btn-sm waves-light btn btn-primary me-10', {'btn-outline': $route.name !== 'patients_details'}]"
                   :to="{name: 'patients_details', params: {id: $route.params.id}}">{{ $t('patients.profile') }}</router-link>
      <router-link :class="['waves-effect btn-sm waves-light btn btn-primary me-10', {'btn-outline': $route.name !== 'patients_cases'}]"
                   :to="{name: 'patients_cases', params: {id: $route.params.id}}">{{ $t('patients.cases') }}</router-link>
      <router-link :class="['waves-effect btn-sm waves-light btn btn-primary me-10', {'btn-outline': $route.name !== 'patients_documents'}]"
                   :to="{name: 'patients_documents', params: {id: $route.params.id}}">{{ $t('patients.documents') }}</router-link>
    </div>

    <div class="col-6 text-end">
      Created Date: {{ patient.created_at }} <span>| Created By: {{ patient.createdBy.full_name }}</span><br>
      Modified Date: {{ patient.updated_at }} <span>| Modified By: {{ patient.updatedBy.full_name }}</span>
    </div>
  </div>

  <div class="row" v-if="patient">
    <div class="col-xl-3 col-12">
      <div class="box">
        <div class="box-header border-0 pb-0">
          <h4 class="box-title">{{ $t('patients.visit_history') }} (Last 5)</h4>
        </div>
        <div class="box-body">
          <div class="widget-timeline-icon">
            <ul>
              <li v-for="history in visitHistory"
                  :key="'history_'+history.id">
                <div class="icon bg-primary fa fa-heart-o"></div>
                <a class="timeline-panel text-muted" href="#">
                  <h4 class="mb-2 mt-1">{{ history.created_at_format }}</h4>
                  <p class="fs-15 mb-0 ">({{ history.cases.length }} Case)</p>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="box" v-if="upcomingAppointments.length">
        <div class="box-header with-border">
          <h4 class="box-title">{{ $t('patients.upcoming_appointment') }}</h4>
        </div>
        <div class="box-body">
          <div class="inner-user-div3">
            <div class="d-flex align-items-center mb-30"
                 v-for="appointment in upcomingAppointments"
                 :key="'appointment_'+appointment.id">
              <div class="me-15">
                <img :src="appointment.doctor.image ? appointment.doctor.image : '/images/avatar-girl.png'"
                     onerror="this.src='/images/avatar-girl.png'"
                     class="avatar avatar-lg rounded10 bg-primary-light" />
              </div>
              <div class="d-flex flex-column flex-grow-1 fw-500">
                <a href="#" class="text-dark hover-primary mb-1 fs-16">{{ appointment.doctor.full_name }}</a>
                <span class="text-fade">{{ appointment.doctorSlot.time_format }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-9 col-12">
      <router-view />
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      upcomingAppointments: [],
      visitHistory: []
    }
  },
  created() {
    this.loadData();
    this.loadUpcomingAppointments();
    this.loadVisitHistory();
  },
  computed: {
    ...mapGetters({
      patient: 'patientModule/getPatient'
    })
  },
  methods: {
    loadVisitHistory() {
      this.visitHistory = [];

      this.axios.get('/patients/visit-history/' + this.$route.params.id)
        .then(res => {
          this.visitHistory = res.data.data;
        })
    },
    loadUpcomingAppointments() {
      this.upcomingAppointments = [];

      this.axios.get('/patients/upcoming-appointments/' + this.$route.params.id)
        .then(res => {
          this.upcomingAppointments = res.data.data;
        })
    },
    loadData() {
      this.$store.dispatch('patientModule/loadPatient', this.$route.params.id);
    }
  }
}
</script>