<template>
  <div class="box">
    <div class="box-header with-border">
      <h4 class="box-title">Previous Visits and Cases</h4>
    </div>
    <div class="box-body">
      <div class="myadmin-dd dd" id="nestable">
        <ol class="dd-list">
          <li class="dd-item" v-for="visit in visits"
              :key="'visit_'+visit.id">
            <div class="dd-handle"
                 @click="$refs.serviceDetailsModal.show(visit)">Visit No.: {{ visit.visit_no }}</div>
            <ol class="dd-list">
              <li class="dd-item" v-for="(c, i) in visit.cases"
                  :key="'case_'+c.id">
                <div class="dd-handle"> Case {{ i+1 }}: {{ c.doctor.full_name }} </div>
                <ol class="dd-list">
                  <li class="dd-item"
                      v-for="service in c.services"
                      :key="'service_'+service.id">
                    <div class="dd-handle">{{ service.name_th }}</div>
                  </li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </div>
    <!-- /.box-body -->
  </div>

  <service-details-modal ref="serviceDetailsModal"></service-details-modal>
</template>

<script>
import ServiceDetailsModal from "@/components/modals/ServiceDetailsModal";
export default {
  components: {ServiceDetailsModal},
  data() {
    return {
      visits: []
    }
  },
  mounted() {
    this.loadVisits();
  },
  methods: {
    initNestable() {
      window.$('#nestable').nestable({
        handleClass: '123'
      })

      window.$('#nestable').nestable('collapseAll');
    },
    loadVisits() {
      this.visits = [];

      this.axios.get('/visits?sort=created_at&sort_order=desc&patient_id=' + this.$route.params.id)
        .then(res => {
          this.visits = res.data.data;

          setTimeout(() => {
            this.initNestable();
          }, 100)
        })
    }
  }
}
</script>