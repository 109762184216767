<template>
  <div class="row">
    <div class="col-md-12">
      <table class="table table-small">
        <thead>
        <tr>
          <th>ชื่อ - นามสกุล (รหัสพนักงาน)</th>
          <th>ตำแหน่ง</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="staff in caseObj.staffs"
            :key="'staff_'+staff.id">
          <td>
            <div class="d-flex align-items-center">
              <div class="me-15">
                <img src="/images/avatar-girl.png" class="h-40 w-40 rounded" />
              </div>
              <div class="d-flex flex-column flex-grow-1 fw-500">
                <a href="#" class="text-dark hover-primary mb-1 fs-16">{{ staff.user.full_name }}</a>
                <span class="text-fade">{{ staff.staff_id }}</span>
              </div>
            </div>
          </td>
          <td>{{ staff.type_text }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: ['caseObj']
}
</script>